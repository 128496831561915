import React, {useEffect} from 'react';
import _ from "lodash";

export default function Replybox( props ) {
    useEffect(() => {
        // let replyboxID = _.get(props, 'pageContext.frontmatter.replybox_id');

        // const replyboxScript = document.createElement('script');
        // replyboxScript.src = 'https://cdn.getreplybox.com/js/embed.js';
        // document.body.appendChild(replyboxScript);

        // if (typeof window !== 'undefined') {
        //     window.replybox = {
        //         site: 'N6lBAzMRAr',
        //         identifier: replyboxID
        //     };
        // }
    });

    return (
        <div id={'replybox'} data-replybox-id={_.get(props, 'pageContext.frontmatter.replybox_id')} />

    );
}