import React from 'react';
import _ from 'lodash';
import moment from 'moment-strftime';

import {Layout, SEO, Tags} from '../components/index';
import {htmlToReact, safePrefix} from '../utils';
import Replybox from "../components/Replybox";
import NewsletterCTA from "../components/NewsletterCTA";

export default class Post extends React.Component {
    render() {
        return (
            <Layout {...this.props}>
                <SEO
                    title={ _.get(this.props, 'pageContext.frontmatter.title')}
                    description={_.get(this.props, 'pageContext.frontmatter.description') || _.get(this.props, 'pageContext.frontmatter.excerpt')}
                    image={_.get(this.props, 'pageContext.frontmatter.img_path') || _.get(this.props, 'pageContext.frontmatter.thumb_img_path')}
                    pathname={_.get(this.props, 'pageContext.fields.slug')}
                    article
                />
              <article className="post post-full">
                <header className="post-header">
                  <h1 className="post-title underline">{_.get(this.props, 'pageContext.frontmatter.title')}</h1>
                </header>
                {_.get(this.props, 'pageContext.frontmatter.subtitle') &&
                <div className="post-subtitle">
                  {htmlToReact(_.get(this.props, 'pageContext.frontmatter.subtitle'))}
                </div>
                }
                {_.get(this.props, 'pageContext.frontmatter.content_img_path') &&
                <div className="post-thumbnail">
                  <img src={safePrefix(_.get(this.props, 'pageContext.frontmatter.content_img_path'))} alt={_.get(this.props, 'pageContext.frontmatter.title')} />
                </div>
                }

                  {/* <!-- Go to www.addthis.com/dashboard to customize your tools --> */}
                  <div className="addthis_inline_share_toolbox" />

                  <div className="post-content">
                  {htmlToReact(_.get(this.props, 'pageContext.html'))}
                </div>
                <footer className="post-meta">
                  <time className="published"
                    dateTime={moment(_.get(this.props, 'pageContext.frontmatter.date')).strftime('%Y-%m-%d %H:%M')}>{moment(_.get(this.props, 'pageContext.frontmatter.date')).strftime('%A, %B %e, %Y')}</time>

                   <Tags {...this.props} />
                   <NewsletterCTA />
                   <Replybox {...this.props}/>
                </footer>
              </article>
            </Layout>
        );
    }
}
